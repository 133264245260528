import Header from "../Header/Header";
import React from "react";
import { Box, Heading, Text, VStack } from "native-base";
import Footer from "../Footer/Footer";
import { useLocation } from "react-router-dom";
import { WebLayout } from "../Layout/components/WebLayout";

export default function () {
  return (
    <>
      <Header />

      <WebLayout bg="#f4f6fc">
        <VStack space={8} px={4} py={20} alignItems={"center"}>
          <Heading
            textAlign={"center"}
            fontWeight={600}
            fontSize="3xl"
            color={"#0c0890"}
          >
            Submitted Successfully
          </Heading>

          <Text textAlign={"center"} fontSize={"lg"} color={"#33333"}>
            Your doctor will be in contact with you soon.
          </Text>
        </VStack>
      </WebLayout>

      <Footer />
    </>
  );
}
