import Header from "../../Header/Header";
import { WebLayout } from "../../Layout/components/WebLayout";
import { Text } from "native-base";

export function Contact() {
  return (
    <>
      <Header />
      <WebLayout>
        <Text>Contact Us</Text>
      </WebLayout>
    </>
  );
}
