import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import {
  PaymentElement,
  PaymentRequestButtonElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

const PaymentInner = React.forwardRef((props, ref) => {
  const elements = useElements();
  const stripe = useStripe();

  useImperativeHandle(ref, () => ({
    confirmPayment: async () => {
      const result = await stripe.confirmPayment({
        elements: elements,
        redirect: "if_required",
      });

      return result;
    },
  }));

  return (
    <>
      <PaymentElement
        onChange={props.handleOnChange}
        options={{
          business: {
            name: "howard healthcare",
          },
          wallets: {
            applePay: "auto",
            googlePay: "auto",
          },
        }}
      />
    </>
  );
});

export default PaymentInner;
