import { Box, Heading, Input, Text, VStack } from "native-base";
import React from "react";
import { Service } from "./Service";
import useNetworkStatus from "../../../Consumer/Common/Hooks/useNetworkStatus";
import Header from "../../Header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { useServices } from "../hooks/useServices";
import { WebLayout } from "../../Layout/components/WebLayout";
import Footer from "../../Footer/Footer";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";
import { AvailableDoctors } from "../../Common/components/AvailableDoctors";

export default function () {
  const { pricingTiers, availableDoctors, statusLoading } =
    useNetworkStatus(false);

  const { results, searchLoading, searchTerm, setSearchTerm } = useServices();

  const servicesList = results.map((service) => (
    <Service
      key={service.objectID}
      pricing={pricingTiers.find((tier) => tier.id === service.pricing)}
      service={service}
    />
  ));

  return (
    <>
      <Header />
      <WebLayout bg="#f4f6fc">
        <VStack space={8} px={4} py={12} alignItems={"center"}>
          <Heading
            textAlign={"center"}
            fontWeight={600}
            fontSize="3xl"
            color={"#0c0890"}
          >
            How can our doctors help you today?
          </Heading>
        </VStack>
      </WebLayout>

      {/*<Input*/}
      {/*  w={"100%"}*/}
      {/*  InputLeftElement={*/}
      {/*    <Box pl={2}>*/}
      {/*      <FontAwesomeIcon color="gray" icon={faSearch} />*/}
      {/*    </Box>*/}
      {/*  }*/}
      {/*  value={searchTerm}*/}
      {/*  size="lg"*/}
      {/*  onChangeText={(val) => setSearchTerm(val)}*/}
      {/*  placeholder="Browse below or search"*/}
      {/*/>*/}

      <VStack space={4} p={4} flex={1}>
        {searchLoading && (
          <Box alignItems={"center"} justifyContent={"center"}>
            <ClipLoader color="#36d7b7" />
          </Box>
        )}

        {servicesList}
      </VStack>

      <Footer />
    </>
  );
}
