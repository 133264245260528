import Header from "../Header/Header";
import React from "react";
import { Form } from "../Form/Form";
import { useStart } from "./hooks/useStart";
import { WebLayout } from "../Layout/components/WebLayout";
import { ProgressBar } from "../Common/components/ProgressBar";
import { useStartProgressBar } from "../Common/hooks/useStartProgressBar";
import { withEnsurePersistedFlow } from "../Common/hocs/wuthEnsurePersistedFlow";
import { Box } from "native-base";

export default withEnsurePersistedFlow(() => {
  const { formSchema, loading, onSubmit } = useStart();
  const { totalSteps, title, subtitle } = useStartProgressBar();

  return (
    <>
      <Header backButton={true} />
      <ProgressBar
        step={1}
        totalSteps={totalSteps}
        title={title}
        subtitle={subtitle}
      />
      <WebLayout loading={loading} p={4}>
        <Form formSchema={formSchema} loading={false} onSubmit={onSubmit} />
      </WebLayout>
    </>
  );
});
