import React from 'react';
import {version} from "./../../../webversion.json";

const useVersionNumber = () => {
  const versionNumber = version;

  return {
    versionNumber
  };
};

export default useVersionNumber;