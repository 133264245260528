import { Box, Text, Input } from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import React from "react";
import Header from "../../Header/Header";
import useNetworkStatus from "../../../Consumer/Common/Hooks/useNetworkStatus";
import { Service } from "./Service";
import { useServiceFilter } from "../hooks/useServiceFilter";
import { WebLayout } from "../../Layout/components/WebLayout";

export default function () {
  const {
    filter,
    results,
    setSearchTerm,
    searchLoading,
    setResults,
    searchTerm,
  } = useServiceFilter();

  const { pricingTiers, statusAvailable, availableDoctors, statusLoading } =
    useNetworkStatus(false);

  const servicesList = results.map((service) => (
    <Service
      key={service.objectID}
      pricing={pricingTiers.find((tier) => tier.id === service.pricing)}
      service={service}
    />
  ));

  return (
    <>
      <Header backButton={true} />
      <WebLayout>
        <Text textAlign="center" fontWeight={600} fontSize="3xl">
          What {filter} can our doctors help you with?
        </Text>

        <Input
          w={"100%"}
          InputLeftElement={
            <Box pl={2}>
              <FontAwesomeIcon color="gray" icon={faSearch} />
            </Box>
          }
          value={searchTerm}
          size="lg"
          onChangeText={(val) => setSearchTerm(val)}
          placeholder={`Search ${filter}s`}
        />

        {servicesList}
      </WebLayout>
    </>
  );
}
