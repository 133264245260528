import React, { memo } from "react";
import useVersionNumber from "../../Consumer/Common/Hooks/useVersionNumber";
import { Box, Text } from "native-base";

const Footer = () => {
  const { versionNumber, codepushVersion } = useVersionNumber();

  return (
    <Box py={4}>
      <Text textAlign="center" color="gray.500" fontSize="xs">
        Howard Healthcare - making health care digital
      </Text>
    </Box>
  );
};

export default memo(Footer);
