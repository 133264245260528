import { usePersistantFlow } from "../../Common/hooks/usePersistantFlow";
import { useLocation, useParams } from "react-router";
import { useEffect } from "react";
import { gql, useQuery, useSubscription } from "@apollo/client";
import { consultFragment } from "../../../Consumer/Common/GraphQL/Fragments/consult";

export const useRequest = () => {
  const { search } = useLocation();
  const { clearFlow, state } = usePersistantFlow();

  const { requestID } = useParams();

  const { loading, data } = useQuery(
    gql`
      ${consultFragment}
      query consult($consultID: ID!) {
        getConsult(id: $consultID) {
          ...consultFragment
        }
      }
    `,
    {
      // pollInterval: 5 * 1000,
      fetchPolicy: "network-only",
      variables: {
        consultID: requestID,
      },
    }
  );

  useSubscription(
    gql`
      ${consultFragment}
      subscription onNewConsult($id: ID!) {
        onConsultUpdated(id: $id) {
          __typename
          ...consultFragment
        }
      }
    `,
    {
      variables: {
        id: requestID,
      },
      shouldResubscribe: true,
    }
  );

  useEffect(() => {
    const qs = new URLSearchParams(search);

    if (qs.get("from")) {
      const flowID = qs.get("from");

      if (flowID === state?.id) clearFlow();
    }
  }, []);

  return { loading, request: data?.getConsult };
};
