import { Box, Heading, Pressable, Text, VStack } from "native-base";
import React, { useState } from "react";
import { WebLayout } from "../Layout/components/WebLayout";

export default function ({ questions }) {
  const [expanded, setExpanded] = useState();

  return (
    <WebLayout bg={"#2033c9"}>
      <VStack space={8} px={4} py={12}>
        <Heading
          textAlign={"center"}
          fontWeight={600}
          fontSize="3xl"
          color={"white"}
        >
          Frequently Asked Questions
        </Heading>

        {questions.map(({ question, answer }) => {
          return (
            <Box>
              <Pressable
                onPressIn={() => {
                  if (expanded !== question) setExpanded(question);
                  if (expanded === question) setExpanded(null);
                }}
              >
                <Text color={"white"} fontWeight={600} fontSize={"md"}>
                  {question}
                </Text>
              </Pressable>

              {expanded === question && <Text color={"white"}>{answer}</Text>}
            </Box>
          );
        })}

        <Text color={"white"}>
          Still have questions? Chat with one of our friendly team members using
          the icon on the right of the screen.
        </Text>
      </VStack>
    </WebLayout>
  );
}
