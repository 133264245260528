import { Box, Heading, HStack, Image, Text } from "native-base";
import React, { memo } from "react";
import ContentLoader from "react-content-loader/native";

const DrLoader = memo(() => (
  <ContentLoader
    speed={2}
    width={130}
    height={40}
    viewBox="0 0 130 40"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    animate={true}
  >
    <rect x="53" y="7" rx="3" ry="3" width="88" height="6" />
    <rect x="54" y="22" rx="3" ry="3" width="52" height="6" />
    <circle cx="22" cy="20" r="20" />
  </ContentLoader>
));
export function AvailableDoctors({ loading, doctors }) {
  return (
    <>
      <HStack space={4}>
        {loading && (
          <>
            <DrLoader /> <DrLoader />
          </>
        )}
        {!loading &&
          doctors.map((doctor) => (
            <HStack
              alignItems="center"
              justifyItems="center"
              flexDir="row"
              key={doctor.name}
            >
              <Box
                w={8}
                h={8}
                mr={2}
                bg={"gray.100"}
                borderRadius="full"
                overflow="hidden"
              >
                <Image w={8} h={8} src={doctor.doctorImage} alt={doctor.name} />
              </Box>
              <Text fontSize="xs" fontWeight={600} color={"white"} maxW={16}>
                {doctor.name}
              </Text>
              <Box
                position={"absolute"}
                bottom={0}
                size={3}
                left={0}
                borderRadius="xl"
                bg="green.600"
              />
            </HStack>
          ))}
      </HStack>
    </>
  );
}
