import { Box, HStack, Text } from "native-base";
import { Link } from "react-router-dom";

export const Menu = () => {
  const items = [
    { name: "Telehealth", link: "/" },
    { name: "Med Cert", link: "/medical-certificates" },
    { name: "Prescriptions", link: "/prescriptions" },
    { name: "About", link: "/doctors" },
  ];

  return (
    <Box flexDir="row" bg="#f4f6fc">
      {items.map((item) => (
        <Box flex={1} p={4} key={item.name} alignItems="center">
          <Link to={item.link}>
            <Text
              fontSize="sm"
              fontWeight={600}
              textAlign={"center"}
              textDecoration="none"
            >
              {item.name}
            </Text>
          </Link>
        </Box>
      ))}
    </Box>
  );
};
