import { Text, HStack, Pressable } from "native-base";
import React from "react";

export const FormFieldOptions = ({
  isError,
  value,
  options,
  error,
  id,
  setFormField,
}) => {
  const selectableOptions = options.map((option) => {
    const selected = value === option;

    const onPress = () => setFormField(id, option);

    return (
      <Pressable
        onPress={onPress}
        alignItems="center"
        justifyItems="center"
        flex={1}
        p={2}
        borderRadius="xl"
        borderWidth={1}
        bg={selected ? "primary.500" : "white"}
        borderColor={selected ? "primary.500" : "gray.200"}
        key={option}
      >
        <Text fontWeight={selected ? 600 : null}>{option}</Text>
      </Pressable>
    );
  });

  return (
    <>
      <HStack flex={1} space={2}>
        {selectableOptions}
      </HStack>

      {isError && (
        <Text fontWeight={600} color="red.600">
          {error}
        </Text>
      )}
    </>
  );
};
