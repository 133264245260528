import { useEffect, useMemo, useState } from "react";
import Ajv from "ajv";
import { log } from "../../../Consumer/Common/Logging";

export const useForm = (formSchema, onSubmit, loading) => {
  const [form, setForm] = useState();

  const setupForm = (clearValues = false) => {
    setForm({
      form: formSchema,
      submitCount: 0,
      errors: Object.fromEntries(
        formSchema.fields.map((field) => [field.id, null])
      ),
      values: Object.fromEntries(
        formSchema.fields.map((field) => {
          const value = clearValues ? null : field.value ?? null;
          return [field.id, value];
        })
      ),
      page: 1,
      valid: false,
    });
  };

  useEffect(() => {
    if (!formSchema) return;

    setupForm();
  }, [formSchema]);

  const validationSchema = useMemo(() => {
    if (!formSchema) return;

    const addFormats = require("ajv-formats");

    const ajv = new Ajv({ allErrors: true });
    require("ajv-errors")(ajv);
    addFormats(ajv);
    ajv.addFormat("mobilenumber", /^04[0-9]{8}$/);

    return ajv.compile(JSON.parse(formSchema.validation));
  }, [formSchema]);

  const setFormField = (fieldName, value) => {
    setForm((prev) => {
      const values = { ...prev.values, [fieldName]: value };
      const valid = validationSchema(values);
      const errors = Object.fromEntries(
        (validationSchema?.errors ?? []).map((error) => {
          const idPath = error.instancePath.substr(1).split("/");

          return [idPath, error.message];
        })
      );

      return {
        ...prev,
        values,
        valid,
        errors,
      };
    });
  };

  const onPress = () => {
    if (loading) return;

    console.log("HI");

    const valid = validationSchema(form.values);
    const errors = Object.fromEntries(
      (validationSchema?.errors ?? []).map((error) => {
        const idPath = error.instancePath.substr(1).split("/");

        return [idPath, error.message];
      })
    );

    setForm((prev) => {
      return { ...prev, valid, errors, submitCount: prev.submitCount + 1 };
    });

    if (!valid) {
      log("Form not valid", errors);

      return;
    }

    if (onSubmit) onSubmit(form.form.id, form.values);
  };

  return { form, setFormField, onPress, setupForm };
};
