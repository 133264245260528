import React from "react";
import { Route, Routes } from "react-router";
import { loadStripe } from "@stripe/stripe-js";
import { BrowserRouter } from "react-router-dom";
import Services from "./Services/components/Services";
import ServiceFilter from "./Services/components/ServiceFilter";
import Info from "./Info/components/Info";
import Start from "./Start/Start";
import { Doctors } from "./Doctors/components/Doctors";
import { Contact } from "./Contact/components/Contact";
import { AboutUs } from "./About/components/About";
import Login from "./Login/components/Login";
import Payment from "./Payment/components/Payment";
import { Profile } from "./Profile/components/Profile";
import Request from "./Request/components/Request";
import { withLDConsumer, withLDProvider } from "launchdarkly-react-client-sdk";
import Home from "./Home/Home";
import MedCert from "./MedCert/MedCert";
import Scripts from "./Scripts/Scripts";
import StartForm from "./Start/StartForm";
import Submitted from "./Start/Submitted";

const stripePromise = loadStripe(
  "pk_test_51Jfw0AKVHpPTRVovwUvGjzDV8MaV1sm5cVTnN21LjISlyA5BhLBSS4xSGRdzODwstICvBakYvnugcUHTGd6FHUcD00zUne2dVg"
);

const WebApp = ({ flags }) => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Home />} path="/" />
        <Route element={<MedCert />} path="/medical-certificates" />
        <Route element={<Scripts />} path="/prescriptions" />
        <Route element={<StartForm />} path="/start" />
        <Route element={<Submitted />} path="/submitted" />

        {flags.ourNewFrontend && (
          <>
            <Route element={<Services />} path="/services" />
            <Route element={<Start />} path="/start" />

            <Route element={<Login />} path="/signin" />
            <Route element={<ServiceFilter />} path="/start/by-:filter" />
            <Route element={<Profile />} path="/profile" />
            <Route element={<Info />} path="/start/info" />
            <Route element={<Request />} path="/request/:requestID" />
            <Route
              element={<Info stripePromise={stripePromise} />}
              path="/start/info"
            />

            <Route
              element={<Payment stripePromise={stripePromise} />}
              path="/start/payment"
            />
          </>
        )}

        <Route element={<Doctors />} path="/doctors" />

        <Route element={<Contact />} path="/contact" />

        <Route element={<AboutUs />} path="/about-us" />
      </Routes>
    </BrowserRouter>
  );
};

export default withLDProvider({
  clientSideID: "635ba268e5577c11a528f499",
})(withLDConsumer()(WebApp));
