import { Box, Text, VStack } from "native-base";
import React from "react";

export function MedicalCert() {
  return (
    <VStack space={4}>
      <Text textAlign="center" fontWeight={600} fontSize="2xl">
        Medical Certificate Requested
      </Text>

      <Text fontSize="md" textAlign="center">
        Thanks for using howard. A doctor will be connected soon with your
        request and will issue your medical certificate!
      </Text>

      <Text fontSize="md" textAlign="center">
        Once issued you will be emailed the PDF certificate. You can leave this
        page now.
      </Text>
    </VStack>
  );
}
