import React from "react";
import { gql, useQuery, useSubscription } from "@apollo/client";

const useNetworkStatus = (polling = true) => {
  useSubscription(
    gql`
      subscription onNetworkStatusChange {
        onNetworkStatusChange {
          id
          __typename
          available
          statusColor
          statusIcon
          statusMessage
          statusMessageHome
          statusMessageOrder
          pricingTiers {
            id
            name
            description
            amount
            amount_per
            available
            statusColor
            statusIcon
            statusMessage
            statusMessageHome
            statusMessageOrder
            __typename
          }
          availableDoctors {
            id
            doctorImage
            name
            __typename
          }
        }
      }
    `,
    {
      shouldResubscribe: true,
      skip: !polling,
    }
  );
  const { data, error, loading } = useQuery(
    gql`
      query networkStatus {
        networkStatus {
          id
          __typename
          available
          statusColor
          statusIcon
          statusMessage
          statusMessageHome
          statusMessageOrder
          pricingTiers {
            id
            name
            description
            amount
            amount_per
            available
            statusColor
            statusIcon
            statusMessage
            statusMessageHome
            statusMessageOrder
            __typename
          }
          availableDoctors {
            id
            doctorImage
            name
            __typename
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
      pollInterval: polling ? 60 * 1000 : null,
    }
  );

  return {
    statusError: error,
    statusLoading: loading,
    statusColor: data?.networkStatus?.statusColor,
    statusIcon: data?.networkStatus?.statusIcon,
    statusAvailable: data?.networkStatus?.available,
    statusMessage: data?.networkStatus?.statusMessage,
    statusMessageHome: data?.networkStatus?.statusMessageHome,
    statusMessageOrder: data?.networkStatus?.statusMessageOrder,
    pricingTiers: data?.networkStatus?.pricingTiers || [],
    availableDoctors: data?.networkStatus?.availableDoctors || [],
  };
};

export default useNetworkStatus;
