import { usePersistantFlow } from "./usePersistantFlow";

export const useStartProgressBar = () => {
  const { state } = usePersistantFlow();

  return {
    totalSteps: 4,
    title: `${state?.service?.name} - $${state?.pricing?.amount}`,
    subtitle: "Delivered via eMail within 30 minutes",
  };
};
