import { Text } from "native-base";
import Header from "../../Header/Header";
import React from "react";
import { WebLayout } from "../../Layout/components/WebLayout";
import useInfo from "../hooks/useInfo";
import { ProgressBar } from "../../Common/components/ProgressBar";
import { Form } from "../../Form/Form";
import { useStartProgressBar } from "../../Common/hooks/useStartProgressBar";
import { withEnsurePersistedFlow } from "../../Common/hocs/wuthEnsurePersistedFlow";

export default withEnsurePersistedFlow(() => {
  const { onSubmit, loading, formSchema, submitting, startedAuthenticated } =
    useInfo();
  const { totalSteps, title, subtitle } = useStartProgressBar();

  const formTitle = (() => {
    if (!startedAuthenticated) return "Create Account";
    return "Verify Your Information";
  })();

  const formMessage = (() => {
    return `We require your information to provide you with the best care possible. Saved for next time to make it easier for you.`;
  })();

  return (
    <>
      <Header backButton={true} />

      <ProgressBar
        step={2}
        totalSteps={totalSteps}
        subtitle={subtitle}
        title={title}
      />

      <WebLayout loading={loading} p={4}>
        <Text textAlign="center" fontWeight={600} fontSize="2xl">
          {formTitle}
        </Text>

        <Text fontSize="md" textAlign="center">
          {formMessage}
        </Text>

        <Form
          formSchema={formSchema}
          onSubmit={onSubmit}
          loading={submitting}
        />
      </WebLayout>
    </>
  );
});
