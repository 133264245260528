import {
  Box,
  Input,
  Heading,
  Text,
  VStack,
  Button,
  Image,
  HStack,
} from "native-base";
import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { WebLayout } from "../Layout/components/WebLayout";
import useNetworkStatus from "../../Consumer/Common/Hooks/useNetworkStatus";
import { AvailableDoctors } from "../Common/components/AvailableDoctors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import FAQS from "../FAQS/FAQS";
import Footer from "../Footer/Footer";
import DocumentTitle from "react-document-title";

export default function () {
  useEffect(() => analytics.page(), []);

  return (
    <DocumentTitle title="Online Prescriptions - Howard Healthcare">
      <>
        <Header />
        <WebLayout bg="#f4f6fc">
          <VStack space={8} px={4} py={12} alignItems={"center"}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize="3xl"
              color={"#0c0890"}
            >
              Online prescriptions and refills here
            </Heading>

            <Text textAlign={"center"} fontSize={"lg"} color={"#33333"}>
              Connect with our doctors in minutes to get your prescription
              directly from your mobile, tablet or computer online 24/7
            </Text>

            <Link to="/start?service=prescription">
              <Button
                bg={"#8bd7f6"}
                size={"lg"}
                px={10}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                $30 | Start Now &gt;
              </Button>
            </Link>

            <HStack alignItems={"center"}>
              {[
                "Accepted by all pharmacists",
                "Sent via SMS to your phone",
              ].map((item, index) => (
                <Text
                  fontSize={"md"}
                  fontWeight={600}
                  color={"#272965"}
                  key={item}
                >
                  <FontAwesomeIcon icon={faCircleCheck} /> {item}
                </Text>
              ))}
            </HStack>
          </VStack>
          <Box alignItems={"center"}>
            <Image source={require("../Assets/7.png")} w={80} h={80} />
          </Box>
        </WebLayout>

        <WebLayout bg={"#f4f6fc"}>
          <VStack space={4} alignItems="center" px={8} py={8}>
            <Heading textAlign={"center"}>
              Get a script in 3 simple steps
            </Heading>

            <Image source={require("../Assets/8.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>First,</Text> complete a quick
              questionnaire describing your symptoms or refill details
            </Text>

            <Image source={require("../Assets/10.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Next,</Text> one of our doctors will review
              your request & contact you if needed
            </Text>

            <Image source={require("../Assets/9.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Lastly,</Text>your prescription will be
              sent directly to you via SMS which you can use at any pharmacy vis
              displaying the QR code
            </Text>

            <Link to="/start?service=prescription">
              <Button
                w={"100%"}
                bg={"#8bd7f6"}
                size={"lg"}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                Start Now
              </Button>
            </Link>
          </VStack>
        </WebLayout>

        <FAQS
          questions={[
            {
              question: "How do I get my prescriptions refilled?",
              answer:
                "It’s easy with Howard to get your script refilled via a telehealth consultation. You will need to provide your past medical history along with your medication history to the doctor assigned to your care.",
            },
            {
              question: "Can you get a prescription from an online doctor? ",
              answer:
                "Yes! Getting a script online from a registered Australian doctor is 100% legitimate. You can request a script from the comfort of your own home via a telehealth consultation purely for your convenience. ",
            },
            {
              question:
                "What kinds of medications can online doctors prescribe? ",
              answer:
                "Most medications can be prescribed via telehealth. Unfortunately, controlled drugs such as narcotics or benzodiazepines, known as schedule 4 and 8, cannot be prescribed.\n",
            },
            {
              question:
                "Can you get a prescription or renew a prescription without talking to a doctor? ",
              answer:
                "Unfortunately, not. At Howard, we require you to have a telehealth consultation with one of our doctors in order to receive a suitable prescription that is safe and effective based on your condition, medical history, and allergies. ",
            },
            {
              question: "How can I get a prescription online?",
              answer:
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lobortis euismod massa pellentesque maximus. Mauris erat neque, ornare a tincidunt sed, tempor eget velit",
            },
            {
              question: "How can I get a prescription online?",
              answer:
                "You can receive a medical script online at Howard. Scripts will be given based on the doctor’s discretion. Once approved by the doctor after a telehealth consultation, you will receive a text with an e-script attached to use at any local pharmacy near you. \n",
            },
          ]}
        />

        <Footer />
      </>
    </DocumentTitle>
  );
}
