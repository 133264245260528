import Header from "../../Header/Header";
import { WebLayout } from "../../Layout/components/WebLayout";
import { Box, Image, Text, VStack, HStack, Heading } from "native-base";
import { useDoctors } from "../hooks/useDoctors";
import React, { useEffect } from "react";
import Footer from "../../Footer/Footer";
import DocumentTitle from "react-document-title";

export function Doctors() {
  const { data, loading } = useDoctors();

  useEffect(() => analytics.page(), []);

  const doctorsList = data.map((doctor) => (
    <HStack space={4} key={doctor.name}>
      <Box w={16} h={16} bg={"gray.100"} borderRadius="full" overflow="hidden">
        <Image w={16} h={16} src={doctor.doctorImage} />
      </Box>
      <VStack space={1} flex={1} justofyContent="center">
        <Text fontWeight={600} fontSize="lg">
          {doctor.name}
        </Text>

        <Text>Howard Doctor - {doctor?.doctorQualifications} </Text>

        <Text color={"gray.500"}>{doctor?.doctorBio}</Text>
      </VStack>
    </HStack>
  ));

  return (
    <DocumentTitle title="About Howard - Howard Healthcare">
      <>
        <Header />
        <WebLayout bg="#f4f6fc">
          <VStack space={8} px={4} py={12} alignItems={"center"}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize="3xl"
              color={"#0c0890"}
            >
              Meet Our Doctors
            </Heading>

            <Text textAlign={"center"} fontSize={"lg"} color={"#33333"}>
              Howard is a digital health clinic which connects you to a
              Australian based doctor in near realtime so you can start feeling
              better, faster - all from the comfort of home.
            </Text>
          </VStack>
        </WebLayout>

        <WebLayout mt={4}>
          <Box p={4}>
            <VStack space={8}>{doctorsList}</VStack>
          </Box>
        </WebLayout>

        <Footer />
      </>
    </DocumentTitle>
  );
}
