import { gql, useMutation } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { usePersistantFlow } from "../../Common/hooks/usePersistantFlow";
import { useAuth } from "../../Common/hooks/useAuth";
import { useNavigate } from "react-router";
import { log } from "../../../Consumer/Common/Logging";

export const usePayment = () => {
  const {
    state: { pricing, service, id: stateId },
  } = usePersistantFlow();

  const { authenticated } = useAuth();

  const navigate = useNavigate();

  const [submitting, setSubmitting] = useState(false);

  const [paymentData, setPaymentData] = useState(null);
  const paymentComponent = useRef();
  const [cardCompleted, setCardCompleted] = useState(false);

  const handleOnChange = (event) => setCardCompleted(event.complete);

  const [requestPaymentIDMutation] = useMutation(
    gql`
      mutation requestPaymentIDMutation($pricetier: String) {
        generatePaymentID(pricetier: $pricetier) {
          customer
          paymentIntentID
          paymentIntentSecret
          ephemeralKey
        }
      }
    `
  );

  const [requestMutation] = useMutation(gql`
    mutation requestMutation(
      $paymentIntentID: String
      $serviceID: String
      $pricingID: String
    ) {
      newConsult(
        paymentIntentID: $paymentIntentID
        serviceID: $serviceID
        pricingID: $pricingID
      ) {
        id
      }
    }
  `);

  const generatePaymentIntent = async () => {
    setPaymentData(null);

    const {
      data: {
        generatePaymentID: {
          customer: paymentCustomer,
          paymentIntentID,
          paymentIntentSecret,
          ephemeralKey,
        },
      },
    } = await requestPaymentIDMutation({
      variables: {
        pricetier: pricing?.id,
      },
    });

    setPaymentData({
      paymentCustomer,
      paymentIntentID,
      paymentIntentSecret,
      ephemeralKey,
    });
  };

  useEffect(() => {
    if (!authenticated) return;

    generatePaymentIntent();
  }, [authenticated]);

  const onPress = async () => {
    if (!cardCompleted) {
      return;
    }

    if (submitting) return;

    setSubmitting(true);

    const {
      paymentIntent: { status: paymentIntentStatus },
    } = await paymentComponent.current.confirmPayment();

    if (paymentIntentStatus !== "requires_capture") {
      return;
    }

    const { data } = await requestMutation({
      variables: {
        paymentIntentID: paymentData.paymentIntentID,
        serviceID: service.objectID,
        pricingID: pricing.id,
      },
    });

    navigate(`/request/${data.newConsult.id}?from=${stateId}`);
  };

  return {
    paymentData,
    loading: paymentData === null,
    paymentComponent,
    onPress,
    submitting,
    handleOnChange,
  };
};
