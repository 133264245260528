import { Box, Text, VStack, HStack, Pressable } from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserDoctorMessage,
  faTablets,
  faChevronRight,
} from "@fortawesome/pro-light-svg-icons";
import React, { memo } from "react";
import { useNavigate } from "react-router";
import { usePersistantFlow } from "../../Common/hooks/usePersistantFlow";

export const Service = memo(
  ({ service, pricing }) => {
    const navigate = useNavigate();

    const { startFlow } = usePersistantFlow();

    const iconType = (() => {
      if (service.type === "medication") return faTablets;
      if (service.type === "service") return faTablets;

      return faUserDoctorMessage;
    })();

    const onPress = () => {
      if (service.type === "menu")
        return navigate(`/by-${service.menu_filter}`);

      startFlow(pricing, service);
    };

    return (
      <Pressable bg={"gray.50"} borderRadius="xl" p={4} onPress={onPress}>
        <HStack space={4}>
          <Box justifyContent="center" alignItems="center">
            <FontAwesomeIcon width={40} height={40} icon={iconType} />
          </Box>

          <VStack space={1} flex={1}>
            <HStack space={2} flexDirection="row" alignItems="center">
              <Text fontSize="xl" fontWeight={600}>
                {service.name}
              </Text>
            </HStack>

            <Text color="gray.500">{service.description}</Text>

            {pricing && (
              <Text fontWeight={500}>
                ${pricing?.amount} per {pricing?.amount_per}
              </Text>
            )}
          </VStack>

          <Box justifyContent="center" alignItems="center">
            <FontAwesomeIcon icon={faChevronRight} />
          </Box>
        </HStack>
      </Pressable>
    );
  },
  (oldProps, newProps) =>
    oldProps.service.objectID === newProps.service.objectID &&
    oldProps.pricing === newProps.pricing
);
