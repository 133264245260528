import Header from "../../Header/Header";
import { WebLayout } from "../../Layout/components/WebLayout";
import { useRequest } from "../hooks/useRequest";
import { MedicalCert } from "./MedicalCert/MedicalCert";

export default function Request() {
  const { request, loading } = useRequest();

  return (
    <>
      <Header />

      <WebLayout loading={loading}>
        <MedicalCert />
      </WebLayout>
    </>
  );
}
