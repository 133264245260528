import { Box, Button, Text } from "native-base";
import Header from "../../Header/Header";
import { WebLayout } from "../../Layout/components/WebLayout";
import { webAuth } from "../../../App";

export function Profile() {
  const clickedSignOut = () => {
    webAuth.logout({
      returnTo: "https://app-dev.howard.healthcare",
    });
  };
  return (
    <>
      <Header />

      <WebLayout>
        <Box>
          <Text>Your Profile</Text>
        </Box>

        <Button onPress={clickedSignOut}>Sign out</Button>
      </WebLayout>
    </>
  );
}
