import { useMemo, useRef, useState } from "react";
import { webAuth } from "../../../App";

export const useLogin = () => {
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null);

  const formRef = useRef();

  const formSchema = useMemo(
    () => ({
      fields: [
        {
          id: "email",
          name: "eMail Address",
          type: "text",
          page: 1,
          value: "luke@lukefuller.co",
        },
        {
          id: "password",
          name: "Password",
          type: "text",
          subType: "password",
          value: 'S89uyi2ho2lj3"',
          page: 1,
        },
      ],
      validation: JSON.stringify({
        type: "object",
        properties: {
          password: { type: "string", minLength: 2 },
          email: {
            type: "string",
            format: "email",
          },
        },
        required: ["email", "password"],
        additionalProperties: false,
        errorMessage: {
          properties: {
            email: "Please enter your eMail address",
            password: "Please enter your password",
          },
        },
      }),
    }),
    []
  );

  const onSubmit = (_, { email, password }) => {
    setLoading(true);
    setError(null);

    webAuth.login(
      {
        username: email,
        realm: "Username-Password-Authentication",
        password: password,
        appState: { returnTo: "/" },
      },
      (result) => {
        if (result.code !== "access_denied") return;

        formRef.current.setupForm(true);
        setLoading(false);
        setError(`${result.error_description} Please try again`);
      }
    );
  };
  return { formSchema, onSubmit, error, loading, formRef };
};
