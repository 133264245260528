import { Box, Text } from "native-base";
import React from "react";

export function ProgressBar({
  step,
  totalSteps,
  title = null,
  subtitle = null,
}) {
  return (
    <>
      <Box bg="blue.50" h={2} w="100%">
        <Box w={`${(step / totalSteps) * 100}%`} bg="blue.300" h={2} />
      </Box>

      {title && (
        <Box alignContent={"center"} mx={8} mt={4} mb={2}>
          <Box p={2} borderRadius="xl" bg="gray.100">
            <Text fontWeight={600} textAlign="center" fontSize="sm">
              {title}
            </Text>
            <Text textAlign="center" fontSize="xs">
              {subtitle}
            </Text>
          </Box>
        </Box>
      )}
    </>
  );
}
