import { useNavigate } from "react-router";
import { atom, useRecoilState } from "recoil";
import { useAuth } from "./useAuth";
import { v4 } from "uuid";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

const persistedFlowState = atom({
  key: "persistedflow",
  default: null,
  effects: [localStorageEffect("persistedflow")],
});

export const usePersistantFlow = () => {
  const navigate = useNavigate();

  const { authenticated } = useAuth();
  const [state, setState] = useRecoilState(persistedFlowState);

  const clearFlow = () => setState(null);

  const startFlow = (pricing, service) => {
    setState({
      pricing,
      service,
      id: v4(),
      forms: {},
      startedAt: new Date(),
      startedAuthenticated: authenticated,
    });

    navigate(`/start`);
  };

  const setForm = (formID, formValues) => {
    setState((prev) => {
      return { ...prev, forms: { ...prev.forms, [formID]: formValues } };
    });
  };

  return { startFlow, state, clearFlow, setForm };
};
