import React from "react";
import { VStack, Text } from "native-base";
import { FormFieldOptions } from "./FormFieldOptions";
import { FormFieldText } from "./FormFieldText";
import { FormFieldDate } from "./FormFieldDate";

export function FormField({ field, error, value, setFormField, submitCount }) {
  const isError = (value && error) || (submitCount > 0 && error);
  const isValid = value && !error;

  return (
    <VStack space={2}>
      <Text fontWeight={600}>{field.name}</Text>

      {field.type === "options" && (
        <FormFieldOptions
          options={field.values}
          value={value}
          error={error}
          isError={isError}
          id={field.id}
          setFormField={setFormField}
        />
      )}

      {field.type === "text" && (
        <FormFieldText
          value={value}
          loading={field.loading}
          id={field.id}
          isError={isError}
          subType={field.subType}
          isValid={isValid}
          setFormField={setFormField}
        />
      )}

      {field.type === "date" && (
        <FormFieldDate
          value={value}
          id={field.id}
          isError={isError}
          isValid={isValid}
          setFormField={setFormField}
        />
      )}
    </VStack>
  );
}
