import React, { memo } from "react";
import { Box, Input } from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

export const FormFieldDate = ({
  value,
  isError,
  isValid,
  id,
  setFormField,
}) => {
  return (
    <Input
      value={value ?? ""}
      type={"date"}
      size="lg"
      InputRightElement={
        <Box p={2}>
          {isError && (
            <FontAwesomeIcon icon={faXmarkCircle} size="sm" color="red" />
          )}
          {isValid && (
            <FontAwesomeIcon icon={faCheckCircle} size="sm" color="green" />
          )}
        </Box>
      }
      onChangeText={(text) => setFormField(id, text)}
    />
  );
};
