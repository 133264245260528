import { useEffect, useState } from "react";
import { useDebounce } from "use-debounce";
import { useAlgolia } from "./useAlgolia";

export const useServices = () => {
  const { client } = useAlgolia();

  const [results, setResults] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermDebounced] = useDebounce(searchTerm, 50);
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    setSearchLoading(true);
    client.search("", { filters: "menu = 1" }).then(({ hits }) => {
      const sortedMenuElements = hits.sort(
        (a, b) => a?.menu_order - b?.menu_order
      );

      setMenuItems(sortedMenuElements);
      setSearchLoading(false);
    });
  }, [client]);

  useEffect(() => {
    if (searchTerm.length === 0) {
      return;
    }

    setSearchLoading(true);
    client.search(searchTermDebounced).then(({ hits }) => {
      setResults(hits);
      setSearchLoading(false);
    });
  }, [searchTermDebounced]);

  return {
    searchLoading,
    results: searchTerm.length > 0 ? results : menuItems,
    searchTerm,
    setSearchTerm,
  };
};
