import {
  Box,
  Input,
  Heading,
  Text,
  VStack,
  Button,
  Image,
  Stack,
} from "native-base";
import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { WebLayout } from "../Layout/components/WebLayout";
import useNetworkStatus from "../../Consumer/Common/Hooks/useNetworkStatus";
import { AvailableDoctors } from "../Common/components/AvailableDoctors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import Footer from "../Footer/Footer";
import FAQS from "../FAQS/FAQS";
import DocumentTitle from "react-document-title";

export default function () {
  const { availableDoctors, statusLoading } = useNetworkStatus(false);

  useEffect(() => analytics.page(), []);

  return (
    <DocumentTitle title="Online Telehealth - Howard Healthcare">
      <>
        <Header />
        <WebLayout bg={"#2033c9"}>
          <VStack space={8} px={4} py={12} alignItems={"center"}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize="3xl"
              color={"white"}
            >
              See an Aussie doctor online today
            </Heading>

            <Text textAlign={"center"} fontSize={"lg"} color={"white"}>
              Our doctors are always accepting consultations from new patients.
              Talk to an Australian registered doctor online today or book in an
              appointment.
            </Text>

            <Link to="/start">
              <Button
                bg={"#8bd7f6"}
                size={"lg"}
                px={10}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                Start Now $60 &gt;
              </Button>
            </Link>

            <AvailableDoctors
              doctors={availableDoctors}
              loading={statusLoading}
            />

            <Image
              position={"absolute"}
              right={0}
              zIndex={-9}
              bottom={0}
              source={require("../Assets/2.png")}
              w={32}
              h={32}
            />
          </VStack>
        </WebLayout>

        <WebLayout>
          <VStack alignItems={"center"} space={8} px={4} py={8}>
            <Heading textAlign={"center"}>
              Your virtual health journey starts here!
            </Heading>

            <Text
              bg={"#d6b3e6"}
              p={2}
              fontWeight={600}
              borderRadius="md"
              color={"#272965"}
              fontSize={"md"}
            >
              Telehealth consultations for only $60{" "}
            </Text>

            <Text textAlign={"center"} fontSize={"md"}>
              Telehealth consultations with Aussie doctors with the click of a
              button. What can a consultation include?
            </Text>

            {[
              "Multi-day medical certificate",
              "Medication Prescriptions",
              "Virtual Examination",
              "STD/STI Screens",
              "COVID PCR Screens",
              "Pathology Tests",
              "Radiology Scans",
            ].map((item, index) => (
              <Text
                fontSize={"md"}
                fontWeight={600}
                color={"#272965"}
                key={item}
              >
                <FontAwesomeIcon icon={faCircleCheck} /> {item}
              </Text>
            ))}
          </VStack>
        </WebLayout>

        <WebLayout bg={"#f4f6fc"}>
          <VStack space={4} alignItems="center" px={4} py={8}>
            <Heading textAlign={"center"}>
              Telehealth Consult in 3 simple steps
            </Heading>

            <Image source={require("../Assets/8.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>First,</Text> complete a quick
              questionnaire describing your symptoms or reason for telehealth
            </Text>

            <Image source={require("../Assets/10.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Next,</Text> one of our doctors will review
              your form & contact you via text, phone or video chat for a 15
              minute consultation
            </Text>

            <Image source={require("../Assets/11.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Lastly,</Text> your doctor will provide you
              with tests, scripts and/or a med cert via text. Test results will
              be sent directly via text or phone call, a follow-up appointment
              may be requested.
            </Text>

            <Link to="/start">
              <Button
                w={"100%"}
                bg={"#8bd7f6"}
                size={"lg"}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                Start Now
              </Button>
            </Link>
          </VStack>
        </WebLayout>

        <FAQS
          questions={[
            {
              question: "What is Howard?",
              answer:
                "Howard is a telehealth platform that allows the Australian public to access high-quality healthcare anytime and anywhere. The company name, Howard, derives from the famous doctor Sir Howard Florey who developed penicillin, a world-renowned antibiotic, into a useful treatment in 1941.",
            },
            {
              question: "What is telehealth?",
              answer:
                "Telehealth, also known as virtual care, allows doctors to deliver medical services to the public over the internet or phone. It allows the public to access healthcare and connect with doctors remotely. ",
            },
            {
              question: "How does telehealth work?",
              answer:
                "Telehealth connects patients to healthcare providers online via text, audio or video. Online telehealth consultations are essentially the same as if you were visiting a doctor in person at a general practice, however, telehealth allows the public to access healthcare from any destination they choose. ",
            },
            {
              question: "What can online doctors treat?",
              answer:
                "Howard’s Australian-licenced doctors can treat many conditions virtually such as bladder infections, UTIs, colds, flu, eye infections, sexual health issues and STD screens, vomiting, diarrhea and much more. Prescriptions, radiology scans, pathology tests and medical certificates are also inclusive of telehealth consultations if required. ",
            },
            {
              question: "Are telehealth consults bulk billed?",
              answer:
                "Unfortunately, not. Howard is a privately owned online telehealth practice with highly trained emergency specialists trained throughout Australia.",
            },
            {
              question: "Can telehealth prescribe medications?",
              answer:
                "Yes, as part of the telehealth consultation, a prescription may be given based on the doctor’s discretion for your overall well-being and safety. ",
            },
            {
              question: "Do online doctors work?",
              answer:
                "Yes! Our doctors at Howard are highly trained within the emergency health profession and can treat various conditions virtually, allowing you to save travel time and skip the waiting room. ",
            },
          ]}
        />

        <Footer />
      </>
    </DocumentTitle>
  );
}
