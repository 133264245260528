import React from "react";
import { ProgressBar } from "../../Common/components/ProgressBar";
import { WebLayout } from "../../Layout/components/WebLayout";
import { Button, HStack, Text, VStack } from "native-base";
import Header from "../../Header/Header";
import { Elements } from "@stripe/react-stripe-js";
import PaymentInner from "./PaymentInner";
import { usePayment } from "../hooks/usePayment";
import { useStartProgressBar } from "../../Common/hooks/useStartProgressBar";
import useNetworkStatus from "../../../Consumer/Common/Hooks/useNetworkStatus";
import { AvailableDoctors } from "../../Common/components/AvailableDoctors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { usePersistantFlow } from "../../Common/hooks/usePersistantFlow";
import { withEnsurePersistedFlow } from "../../Common/hocs/wuthEnsurePersistedFlow";

export default withEnsurePersistedFlow(({ stripePromise }) => {
  const {
    paymentData,
    paymentComponent,
    onPress,
    submitting,
    loading,
    handleOnChange,
  } = usePayment();
  const { availableDoctors, statusLoading } = useNetworkStatus(false);

  const { totalSteps, title, subtitle } = useStartProgressBar();

  const {
    state: { pricing, service },
  } = usePersistantFlow();

  return (
    <>
      <Header backButton={true} />

      <ProgressBar
        step={3}
        totalSteps={totalSteps}
        subtitle={subtitle}
        title={title}
      />

      <WebLayout spacing={8} loading={loading}>
        <Text textAlign="center" fontWeight={600} fontSize="2xl">
          Payment & Request
        </Text>

        <Text fontSize="md" textAlign="center">
          Last step! After payment your {service.name} request will be reviewed
          by one of our online doctors and emailed to you.
        </Text>

        <AvailableDoctors doctors={availableDoctors} loading={statusLoading} />

        <VStack space={4} p={4} bg="gray.100" borderRadius="lg">
          <HStack space={2} alignItems={"center"}>
            <FontAwesomeIcon icon={faLock} />
            <Text fontWeight={600} fontSize={"md"}>
              Secure Payment of ${pricing.amount}
            </Text>
          </HStack>
          <Elements
            stripe={stripePromise}
            options={{
              clientSecret: paymentData?.paymentIntentSecret,
            }}
          >
            <PaymentInner
              handleOnChange={handleOnChange}
              ref={paymentComponent}
            />
          </Elements>
        </VStack>

        <Button
          isLoading={submitting}
          size="lg"
          onPress={onPress}
        >{`Request ${pricing.name} >`}</Button>

        <Text color="gray.400" textAlign="center" fontSize="xs">
          By requesting you agree to Howards terms & conditions of use which can
          be found by clicking here
        </Text>
      </WebLayout>
    </>
  );
});
