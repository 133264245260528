import { gql, useQuery } from "@apollo/client";

export const useDoctors = () => {
  const { data, loading } = useQuery(
    gql`
      query doctorQuery {
        getDoctors {
          doctorImage
          doctorQualifications
          doctorRegistration
          doctorBio
          id
          name
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  return { data: data?.getDoctors ?? [], loading };
};
