import React, { useEffect, useMemo } from "react";
import { ModalProvider } from "react-native-use-modal-hooks";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Text } from "react-native";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { version } from "./webversion.json";
import { Box, extendTheme, NativeBaseProvider } from "native-base";
import WebApp from "./WebApp/WebApp";
import auth0 from "auth0-js";
import { RecoilRoot, atom, useRecoilState } from "recoil";

const isProd = document.location.host === "app.howard.healthcare";

Sentry.init({
  dsn: "https://dbdea9f3737a4d58aba81d708edc8c43@o1123776.ingest.sentry.io/6161957",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  beforeBreadcrumb(breadcrumb, hint) {
    if (!isProd) {
      console.log(breadcrumb);
    }
    return breadcrumb;
  },
  enabled: !__DEV__,
  environment: isProd ? "production" : "dev",
  release: version,
});

const apiURL = "https://api.howard.healthcare/graphql";
const wssURL = "wss://api.howard.healthcare/graphql/realtime";

function FallbackComponent() {
  return <Text>An error has occurred</Text>;
}

const myFallback = <FallbackComponent />;

export const webAuth = new auth0.WebAuth({
  domain: "auth0.howard.healthcare",
  audience: "https://api.howard.healthcare",
  clientID: "E7zPES8cBvrWxh88fYhGoEqPmSNSqSwX",
  responseType: "token",
  grant_type: "http://auth0.com/oauth/grant-type/password-realm",
  redirectUri: `https://app-dev.howard.healthcare/auth-callback`,
});

export const authenticationStateAtom = atom({
  key: "authentication",
  default: { authenticated: false, accessToken: null, loading: true },
});

const AppWeb = () => {
  const [authenticationState, setAuthenticationState] = useRecoilState(
    authenticationStateAtom
  );

  useEffect(() => {
    webAuth.checkSession({}, function (err, authResult) {
      if (authResult?.accessToken) {
        setAuthenticationState({
          authenticated: true,
          loading: false,
          accessToken: authResult.accessToken,
        });
      } else {
        setAuthenticationState({
          authenticated: false,
          loading: false,
        });
      }
    });
  }, []);

  const graphClient = useMemo(() => {
    const wsAuth = authenticationState.authenticated
      ? {
          type: "OPENID_CONNECT",
          jwtToken: async () => {
            return `Bearer ${authenticationState.accessToken}`;
          },
        }
      : {
          type: "API_KEY",
          apiKey: "da2-2zzbkv7iyvggtmpiinwclvslhy",
        };

    const httpLink = new HttpLink({
      uri: apiURL,
    });

    const authLink = createAuthLink({
      url: apiURL,
      region: "ap-southeast-2",
      auth: wsAuth,
    });

    const wsLink = createSubscriptionHandshakeLink(
      {
        url: wssURL,
        region: "ap-southeast-2",
        auth: wsAuth,
      },
      httpLink
    );

    const link = ApolloLink.from([authLink, wsLink]);

    return new ApolloClient({
      cache: new InMemoryCache(),
      link: link,
    });
  }, [authenticationState.authenticated, authenticationState.accessToken]);

  if (authenticationState.loading) return null;

  return (
    <Sentry.ErrorBoundary fallback={myFallback} showDialog>
      <ModalProvider>
        <ApolloProvider client={graphClient}>
          <WebApp />
        </ApolloProvider>
      </ModalProvider>
    </Sentry.ErrorBoundary>
  );
};

const AuthCallbackPage = () => {
  useEffect(() => {
    webAuth.parseHash(
      { hash: window.location.hash },
      function (err, authResult) {
        if (err) {
          console.log(err);

          window.location = "/";

          return;
        }

        if (authResult?.appState?.returnTo) {
          window.location = authResult.appState.returnTo;

          return;
        }

        window.location = "/";
      }
    );
  }, []);
  return <Box></Box>;
};

const theme = extendTheme({
  fonts: {
    heading: "DM Serif Display",
    body: "DM Sans",
  },
});

const AppOuter = function () {
  const isAuth = window.document.location.pathname === "/auth-callback";

  return (
    <RecoilRoot>
      <NativeBaseProvider theme={theme}>
        {isAuth ? <AuthCallbackPage /> : <AppWeb />}
      </NativeBaseProvider>
    </RecoilRoot>
  );
};

export default AppOuter;
