import React, { memo, useState } from "react";
import { Box, Pressable, HStack, Text, Button } from "native-base";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faUserCircle,
  faBars,
  faStethoscope,
} from "@fortawesome/pro-light-svg-icons";
import { useLocation, useNavigate } from "react-router";
import { Menu } from "../Menu/components/Menu";
import { useAuth } from "../Common/hooks/useAuth";
import { usePersistantFlow } from "../Common/hooks/usePersistantFlow";
import { withLDConsumer } from "launchdarkly-react-client-sdk";

const Header = ({ backButton = false, hideRight = false, flags }) => {
  const navigate = useNavigate();

  const { authenticated, loading } = useAuth();

  const { pathname } = useLocation();

  const { state, clearFlow } = usePersistantFlow();

  //!!state && !
  const inProgressState = pathname.startsWith("/start");

  const [showMenu, setShowMenu] = useState(false);

  return (
    <>
      <Box
        alignItems="center"
        justifyContent="center"
        flexDirection="row"
        h={16}
        px={2}
      >
        <Box w={20} pl={4} alignItems="flex-start">
          {backButton && (
            <Pressable onPress={() => navigate(-1)}>
              <FontAwesomeIcon icon={faChevronLeft} size={"lg"} />
            </Pressable>
          )}

          {/*{!backButton && (*/}
          {/*  <Pressable onPress={() => setShowMenu(!showMenu)}>*/}
          {/*    <FontAwesomeIcon icon={faBars} size={"lg"} />*/}
          {/*  </Pressable>*/}
          {/*)}*/}
        </Box>

        <Pressable flex={1} onPress={() => navigate("/")}>
          <HStack
            space={2}
            flex={1}
            alignItems="center"
            justifyContent="center"
            flexDirection="row"
          >
            <Text
              fontWeight={700}
              color={"#0c0890"}
              fontSize="2xl"
              fontFamily={"Playfair Display"}
            >
              howard
            </Text>
            <FontAwesomeIcon
              icon={faStethoscope}
              color={"#0c0890"}
              size={"lg"}
            />
          </HStack>
        </Pressable>

        <Box w={20} alignItems="flex-end" pr={4}>
          {flags.ourNewFrontend && !hideRight && authenticated && !loading && (
            <Pressable onPress={() => navigate("/profile")}>
              <FontAwesomeIcon icon={faUserCircle} size={"lg"} />
            </Pressable>
          )}

          {flags.ourNewFrontend && !hideRight && !authenticated && !loading && (
            <Pressable onPress={() => navigate("/signin")}>
              <Text>Sign In</Text>
            </Pressable>
          )}
        </Box>
      </Box>

      {!inProgressState && <Menu />}

      {/*{inProgressState && (*/}
      {/*  <Box bg="gray.100" p={4}>*/}
      {/*    <Text textAlign={"center"} fontSize="md" py={2} fontWeight={600}>*/}
      {/*      You have an in progress request.*/}
      {/*    </Text>*/}

      {/*    <HStack space={2}>*/}
      {/*      <Button flex={1} onPress={() => navigate("/start")}>*/}
      {/*        Resume*/}
      {/*      </Button>*/}

      {/*      <Button variant="ghost" flex={1} onPress={() => clearFlow()}>*/}
      {/*        Cancel*/}
      {/*      </Button>*/}
      {/*    </HStack>*/}
      {/*  </Box>*/}
      {/*)}*/}
    </>
  );
};

export default withLDConsumer()(Header);
