import { Box, VStack } from "native-base";
import { ClipLoader } from "react-spinners";
import React from "react";

export function WebLayout({
  children,
  bg,
  loading = false,
  spacing = 4,
  p = 0,
}) {
  return (
    <Box alignItems="center" bg={bg}>
      <Box maxW={900} p={p}>
        <VStack space={spacing} flex={1}>
          {loading && <ClipLoader color="black" />}
          {!loading && children}
        </VStack>
      </Box>
    </Box>
  );
}
