import { usePersistantFlow } from "../hooks/usePersistantFlow";
import Header from "../../Header/Header";
import React from "react";
import { WebLayout } from "../../Layout/components/WebLayout";
import { Button, Text } from "native-base";
import { useNavigate } from "react-router";

export const withEnsurePersistedFlow = (Component) => {
  return (props) => {
    const { state } = usePersistantFlow();
    const navigate = useNavigate();

    if (state === null) {
      return (
        <>
          <Header />
          <WebLayout>
            <Text textAlign="center" fontWeight={600} fontSize="2xl">
              Session Expired
            </Text>

            <Text fontSize="md" textAlign="center">
              Sorry! Looks like your session has expired. Please start again.
            </Text>

            <Button onPress={() => navigate("/")}>Start Again</Button>
          </WebLayout>
        </>
      );
    }

    return <Component {...props} />;
  };
};
