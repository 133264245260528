import React, { forwardRef, memo, useImperativeHandle } from "react";
import { Button, Heading, Text, VStack } from "native-base";
import { FormField } from "./FormField";
import { useForm } from "./hooks/useForm";

export const Form = memo(
  forwardRef(
    ({ formSchema, onSubmit, loading, submitButtonTitle = "Next" }, ref) => {
      const { form, onPress, setFormField, setupForm } = useForm(
        formSchema,
        onSubmit,
        loading
      );

      useImperativeHandle(ref, () => ({
        setupForm,
      }));

      const fields = form?.form?.fields
        .filter((field) => field.page === form.page)
        .map((field) => (
          <FormField
            key={field.id}
            submitCount={form.submitCount}
            setFormField={setFormField}
            value={form.values[field.id]}
            error={form.errors[field.id]}
            loading={loading}
            field={field}
          />
        ));

      return (
        <VStack space={4} flex={1}>
          {form?.form?.description && (
            <Heading mb={2} textAlign="center" fontSize="lg">
              {form?.form?.description}
            </Heading>
          )}

          {fields}

          <Button size="lg" onPress={onPress} isLoading={loading}>
            {submitButtonTitle}
          </Button>
        </VStack>
      );
    }
  ),
  (oldProps, newProps) =>
    oldProps.loading === newProps.loading &&
    oldProps?.formSchema?.id === newProps?.formSchema?.is
);
