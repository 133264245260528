import React, { useEffect, useState } from "react";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import { useAuth } from "../../Common/hooks/useAuth";
import { webAuth } from "../../../App";
import { faker } from "@faker-js/faker";

export const customerFragment = gql`
  fragment customerFragment on Customer {
    __typename
    id
    given
    surname
    dob
    completedProfile
    email
    mobile
    gender
  }
`;

const GET_CUSTOMER = gql`
  ${customerFragment}
  query customerSessionQuery {
    getCustomer {
      ...customerFragment
    }
  }
`;

const useInfo = () => {
  const navigate = useNavigate();

  const { authenticated } = useAuth();

  const [submitting, setSubmitting] = useState(false);
  const [startedAuthenticated] = useState(authenticated);

  const [formSchema, setFormSchema] = useState(undefined);

  const [signupCustomerMutation, { loading: signingup }] = useMutation(gql`
    mutation signupCustomerMutation(
      $given: String
      $surname: String
      $mobile: String
      $email: String
      $gender: String
      $dob: String
      $password: String
    ) {
      signUpCustomer(
        given: $given
        surname: $surname
        mobile: $mobile
        email: $email
        dob: $dob
        gender: $gender
        password: $password
      ) {
        id
      }
    }
  `);

  const [editCustomerMutation, { loading: saving }] = useMutation(gql`
    ${customerFragment}
    mutation editCustomerMutation(
      $given: String
      $surname: String
      $mobile: String
      $email: String
      $gender: String
      $dob: String
    ) {
      updateCustomer(
        given: $given
        surname: $surname
        mobile: $mobile
        email: $email
        dob: $dob
        gender: $gender
      ) {
        ...customerFragment
      }
    }
  `);

  const setupFormSchema = (customer = null) => {
    setFormSchema({
      fields: [
        {
          id: "given",
          name: "Given Name",
          type: "text",
          page: 1,
          value: customer?.given,
        },
        {
          id: "surname",
          name: "Surname",
          type: "text",
          page: 1,
          value: customer?.surname,
        },
        {
          id: "email",
          name: "eMail",
          type: "text",
          page: 1,
          value: customer?.email,
        },
        ...(!startedAuthenticated
          ? [
              {
                id: "password",
                name: "Password",
                type: "text",
                subType: "password",
                page: 1,
                value: customer?.password,
              },
            ]
          : []),
        {
          id: "dob",
          name: "DOB",
          type: "date",
          page: 1,
          value: customer?.dob,
        },
        {
          id: "mobile",
          name: "Mobile",
          type: "text",
          page: 1,
          value: customer?.mobile,
        },
        {
          id: "gender",
          name: "Gender",
          type: "options",
          values: ["Female", "Male", "Other"],
          page: 1,
          value: customer?.gender,
        },
      ],
      validation: JSON.stringify({
        type: "object",
        properties: {
          given: { type: "string", minLength: 2 },
          surname: { type: "string", minLength: 2 },
          password: { type: "string", minLength: 2 },
          email: {
            type: "string",
            format: "email",
          },
          dob: {
            type: "string",
            format: "date",
          },
          mobile: { type: "string", format: "mobilenumber" },
          gender: { enum: ["Female", "Male", "Other"] },
        },
        required: [
          "given",
          "surname",
          "dob",
          "email",
          "mobile",
          "gender",
          ...(!startedAuthenticated ? ["password"] : []),
        ],
        additionalProperties: false,
        errorMessage: {
          properties: {
            gender: "Select a gender",
          },
        },
      }),
    });
  };

  useEffect(() => {
    if (!startedAuthenticated)
      setupFormSchema({
        given: faker.name.firstName(),
        surname: faker.name.lastName(),
        email: faker.internet.email(),
        dob: faker.date.past(18).toISOString().split("T")[0],
        mobile: faker.phone.phoneNumber("04########"),
        password: faker.internet.password(),
        gender: "Male",
      });
  }, []);

  useQuery(GET_CUSTOMER, {
    fetchPolicy: "cache-and-network",
    skip: !startedAuthenticated,
    onCompleted: (data) => {
      const customer = data?.getCustomer;
      setupFormSchema(customer);
    },
  });

  const onSubmit = async (form, values) => {
    setSubmitting(true);

    if (!startedAuthenticated) {
      await signupCustomerMutation({
        variables: {
          given: values.given,
          surname: values.surname,
          mobile: values.mobile,
          email: values.email,
          gender: values.gender,
          dob: values.dob,
          password: values.password,
        },
      });

      webAuth.login(
        {
          username: values.email,
          realm: "Customers",
          password: values.password,
          appState: { returnTo: "/start/payment" },
        },
        (result) => {}
      );

      return;
    }

    await editCustomerMutation({
      variables: {
        given: values.given,
        surname: values.surname,
        mobile: values.mobile,
        email: values.email,
        gender: values.gender,
        dob: values.dob,
      },
    });

    navigate("/start/payment");
  };

  return {
    loading: formSchema === undefined,
    submitting,
    formSchema,
    startedAuthenticated,
    onSubmit,
  };
};

export default useInfo;
