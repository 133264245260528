import { Alert, Text } from "native-base";
import React from "react";
import { WebLayout } from "../../Layout/components/WebLayout";
import Header from "../../Header/Header";
import { useLogin } from "../hooks/useLogin";
import { Form } from "../../Form/Form";

export default function () {
  const { formSchema, loading, error, onSubmit, formRef } = useLogin();

  return (
    <>
      <Header />

      <WebLayout>
        <Text textAlign="center" fontWeight={600} fontSize="2xl">
          Sign In
        </Text>

        <Text fontSize="md" textAlign="center">
          Manage your health profile with howard
        </Text>

        {error && (
          <Alert w="100%" status="error">
            <Text fontWeight={600}>{error}</Text>
          </Alert>
        )}

        <Form
          formSchema={formSchema}
          loading={loading}
          onSubmit={onSubmit}
          ref={formRef}
          submitButtonTitle="Sign In"
        />
      </WebLayout>
    </>
  );
}
