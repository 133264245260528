import {
  Box,
  Input,
  Heading,
  Text,
  VStack,
  Button,
  Image,
  HStack,
} from "native-base";
import React, { useEffect } from "react";
import Header from "../Header/Header";
import { Link } from "react-router-dom";
import { WebLayout } from "../Layout/components/WebLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/pro-light-svg-icons";
import FAQS from "../FAQS/FAQS";
import Footer from "../Footer/Footer";
import DocumentTitle from "react-document-title";

export default function () {
  useEffect(() => analytics.page(), []);

  return (
    <DocumentTitle title="Medical Certificates - Howard Healthcare">
      <>
        <Header />
        <WebLayout bg="#f4f6fc">
          <VStack space={8} px={4} py={12} alignItems={"center"}>
            <Heading
              textAlign={"center"}
              fontWeight={600}
              fontSize="3xl"
              color={"#0c0890"}
            >
              Need a medical certificate today?
            </Heading>

            <Text textAlign={"center"} fontSize={"lg"} color={"#33333"}>
              Affordable, fast and reliable medical certificates delivered
              directly to your mobile for your confidence!
            </Text>

            <Link to="/start?service=medicalcertificate">
              {" "}
              <Button
                bg={"#8bd7f6"}
                size={"lg"}
                px={10}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                $30 | Start Now &gt;
              </Button>
            </Link>

            <HStack space={2} alignItems={"center"}>
              {["Accepted by all workplaces", "No booking required"].map(
                (item, index) => (
                  <Text
                    fontSize={"md"}
                    fontWeight={600}
                    color={"#272965"}
                    key={item}
                  >
                    <FontAwesomeIcon icon={faCircleCheck} /> {item}
                  </Text>
                )
              )}
            </HStack>
          </VStack>
          <Box alignItems={"center"}>
            <Image source={require("../Assets/5.png")} w={80} h={80} />
          </Box>
        </WebLayout>

        <WebLayout bg={"#f4f6fc"}>
          <VStack space={4} alignItems="center" px={8} py={8}>
            <Heading textAlign={"center"}>
              Medical certificate in 3 simple steps
            </Heading>

            <Image source={require("../Assets/8.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>First,</Text> complete a quick
              questionnaire describing your symptoms
            </Text>

            <Image source={require("../Assets/10.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Next,</Text> one of our doctors will review
              your form & contact you if needed
            </Text>

            <Image source={require("../Assets/11.png")} w={20} h={20} />

            <Text textAlign="center" fontSize={"md"}>
              <Text fontWeight={600}>Lastly,</Text> your medical certificate
              will be sent directly to your mobile requested.
            </Text>

            <Link to="/start?service=medicalcertificate">
              {" "}
              <Button
                w={"100%"}
                bg={"#8bd7f6"}
                size={"lg"}
                _text={{ color: "#0c0890", fontWeight: 800 }}
              >
                Start Now
              </Button>
            </Link>
          </VStack>
        </WebLayout>

        <FAQS
          questions={[
            {
              question:
                "How long does it take to receive a medical certificate?",
              answer:
                "The online questionnaire only takes a few minutes to complete. Please allow up to 2 hours for the doctor to review, approve and send your medical certificate to your mobile.",
            },
            {
              question: "Can my medical certificate be backdated? ",
              answer:
                "Unfortunately, we do not backdate medical certificates. We can only offer a medical certificate on the day you request.",
            },
            {
              question: "How many days can I request a medical certificate?",
              answer:
                "You can simply request a one-day medical certificate which costs $30, or if you require more than one day you must talk to one of our doctors via telehealth for a thorough examination, which costs $60. Simply start the online questionnaire and the form will direct you to telehealth if you request more than one day. ",
            },
            {
              question: "Will I be guaranteed a medical certificate? ",
              answer:
                "The doctor will review the questionnaire and assess if it is appropriate for you to receive a medical certificate in the best interest of your health. If the request is not accepted, you will be notified of the outcome and the payment will not proceed.",
            },
            {
              question:
                "Will Medicare cover the cost of the medical certificate?",
              answer:
                "Unfortunately, this service has no Medicare rebate as Howard is a privately billed online practice.",
            },
            {
              question:
                "What details are legally required on a medical certificate? ",
              answer:
                "As per Australian guidelines, the standard requirements for a medical certificate are:\n" +
                "\t•\tName and address of the doctor issuing the certificate\n" +
                "\t•\tName of the patient\n" +
                "\t•\tThe date on which the examination took place\n" +
                "\t•\tThe date on which the certificate was issued\n" +
                "\t•\tDate(s) on which the patient is or was unfit for attendance",
            },
            {
              question:
                "Will my employee accept an online medical certificate? ",
              answer:
                "Yes, all doctors working at Howard are certified healthcare physicians registered with the Australian Health Practitioner Registration Association (AHPRA). The medical certificates certified by our doctors meet all requirements under the Fair Work Act 2009 for your employee to accept.\n",
            },
          ]}
        />

        <Footer />
      </>
    </DocumentTitle>
  );
}
