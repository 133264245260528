import { useParams } from "react-router";
import { useAlgolia } from "./useAlgolia";
import { useEffect, useState } from "react";

export const useServiceFilter = () => {
  const { filter } = useParams();

  const { client } = useAlgolia();

  const [searchLoading, setSearchLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setSearchLoading(true);
    client
      .search(searchTerm, { filters: `(type:${filter})` })
      .then(({ hits }) => {
        setResults(hits);
        setSearchLoading(false);
      });
  }, [client, searchTerm]);

  return {
    setResults,
    searchLoading,
    filter,
    setSearchTerm,
    searchTerm,
    results,
  };
};
