import { useMemo } from "react";
import algoliasearch from "algoliasearch";

export const useAlgolia = () => {
  const client = useMemo(() => {
    const _client = algoliasearch(
      "RFO8WVZF1N",
      "5c6562e5504a43c06ceac82076dbf828"
    );
    return _client.initIndex("services");
  }, []);

  return { client };
};
