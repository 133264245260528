import { useNavigate } from "react-router";
import React, { useMemo } from "react";
import { gql, useQuery } from "@apollo/client";
import { usePersistantFlow } from "../../Common/hooks/usePersistantFlow";

export const useStart = () => {
  const {
    state: { pricing, service, forms },
    setForm,
  } = usePersistantFlow();

  const navigate = useNavigate();

  const { data, loading } = useQuery(
    gql`
      query startQuery($id: String!, $service: String!) {
        formForService(id: $id, service: $service, stage: PreQualify) {
          description
          id
          title
          validation
          fields {
            explainer
            id
            name
            order
            page
            required
            type
            value
            values
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",

      variables: {
        id: pricing?.id,
        service: service?.objectID,
      },
    }
  );

  const formSchema = useMemo(() => {
    if (!data?.formForService) return null;

    const _formSchema = data?.formForService;

    if (forms[_formSchema.id]) {
      return {
        ..._formSchema,
        fields: _formSchema.fields.map((field) => {
          if (forms[_formSchema.id][field.id]) {
            return {
              ...field,
              value: forms[_formSchema.id][field.id],
            };
          }

          return field;
        }),
      };
    }

    return _formSchema;
  }, [data]);

  const onSubmit = (id, values) => {
    setForm(id, values);

    return navigate("/start/info");
  };

  return {
    loading: loading || formSchema === null,
    formSchema,
    onSubmit,
  };
};
