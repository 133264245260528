import { gql } from "@apollo/client";

export const consultFragment = gql`
  fragment consultFragment on Consult {
    __typename
    id
    state
    requestedBy {
      __typename
      id
      name
      mobile
      email
      dob
      gender
    }
    pricingTier {
      id
      name
    }
    requestedAt
    customerEndedAt
    customerFeedback
    customerRating
    state
    doctorNotes
    videoCall
    videoCallAnswered
    videoCallAnsweredAt
    doctorAssigned {
      __typename
      id
      name
    }
    doctorAssignedAt
    billed
    billedError
    billedAmount
    billedDoctorAmount
    billedPlatformFee
    timedOut
  }
`;
